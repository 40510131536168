* {
    box-sizing: border-box;
}

html {
    /* Prevent bounce effect on mobile devices */
    width: 100%;
    height: 100%;
    overflow: hidden;
    overscroll-behavior-y: none;
}

body {
    background-color: rgb(255, 0, 0);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    /* font-size: 0.8125rem; */
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    color: black;
    background-color: rgb(89, 89, 89);
}

.video-container {
    position: absolute;
    overflow: hidden;
    display: flex;
    width: 100vw;
    height: 100vh;
    height: 100svh;
    left: 0;
    top: 0;
    background-color: rgb(89, 85, 224);
    z-index: -2;
}

.video-canvas {
    position: relative;
    overflow: hidden;
    object-fit: cover;
    /* object-fit: contain; */
    width: 100%;
    height: 100%;

}

.photo-container {
    position: absolute;
    display: flex;
    width: 25vw;
    height: 25vh;
    height: 25svh;
    background-color: black;
    top: 20px;
    left: 20px;
    border-radius: 8px;
    border: 1px solid white;
    z-index: 1;
}

.photo-canvas {
    position: relative;
    overflow: hidden;
    object-fit: cover;
    /* object-fit: contain; */
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 1;
}

.button {
    position: relative;
    margin: 0 auto;
    display: inline-block;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    font-size: 1em;
    outline: none;
    padding: 13px 23px;
    text-align: center;
    touch-action: manipulation;
    transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
    user-select: none;
    -webkit-user-select: none;
}

.button:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow 0.2s;
}

.button:active {
    background-color: #f7f7f7;
    border-color: #000000;
    transform: scale(0.96);
}

.button:disabled {
    border-color: #dddddd;
    color: #dddddd;
    cursor: not-allowed;
    opacity: 1;
}

.darker-20 {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: -1;
}

.darker-40-blur {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}


.info-panel {
    position: absolute;
    background-color: transparent;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1em;
    text-align: center;
    vertical-align: bottom;

}

.info-panel-inner {
    padding: 2em;
}


.input-grid {
    margin-top: 2em;
    display: grid;
    grid-template-columns: 1fr ;
    grid-column-gap: 4px; 
    align-items: center;
    align-self: center;
    width: 100%;
}

.input-grid-item {
    padding-top: 0.5em;
    text-align: center;
    width: 100%;
}

.info-panel > input {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    font-size: 1em;
    outline: none;
    padding: 13px 23px;
    margin-bottom: 1.0em;
    width: 100%;
}

.input-grid-item label {
    font-size: 1rem;
    display: inline-block;
    text-align: right;
    width: 100%;
}

.input-grid-item input {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    font-size: 1rem;
    text-align: left;
    width: 100%;
    padding: 4px;
}

.spinner-container {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}


@keyframes anim-spinner {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.spinner > img {
    opacity: 0.6;
    width: 100vw;
    height: 100vh;
    animation-duration: 8s;
    animation-name: anim-spinner;
    animation-iteration-count: infinite;
}
